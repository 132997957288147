import request from '@/util/request'

export function GetCellTaskList(params) {
  return request({
    url: '/CellTask.asmx/GetCellTaskList',
    method: 'GET',
    params,
  })
}

export function GetCellTask(params) {
  return request({
    url: '/CellTask.asmx/GetCellTask',
    method: 'GET',
    params,
  })
}
